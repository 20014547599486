import Layout from "components/comachicart/Layout";
import MediaDetail from "components/comachicart/MediaDetail";
import { parse } from "date-fns";
import SEO from "components/seo";
import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import sampleImgSecond from "images/comachi_sample_05.png";

import { owned_media_list } from "../../constants";

const MediaPage = () => {
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("id");

  // この値をクエリかなんかで読む
  const mediaId = paramValue;
  const fetchData = async () => {
    try {
      const response = await fetch(owned_media_list, { cache: "no-store" });
      const jsonData = await response.json();
      const foundItem = jsonData.find(item => item.id === Number(mediaId));
      setData(foundItem);
    } catch (error) {
      console.log("データの取得中にエラーが発生しました:", error);
    }
  };
  return (
    <Layout>
      <SEO title="comachicart" path="comachicart/media" />
      {data != null && <MediaDetail data={data} />}
    </Layout>
  );
};

export default MediaPage;
