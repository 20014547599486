import classNames from "classnames";
import React from "react";
import Style from "styles/components/comachicart/owned_media.module.scss";
import { useEffect, useRef } from "react";

const MediaDetail = ({ data }) => {
  // JSON内のクラスを動的に設定
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      const alignRight = containerRef.current.querySelector(".ql-align-right");
      if (alignRight) {
        alignRight.style.textAlign = "right";
      }
      const alignCenter = containerRef.current.querySelector(".ql-align-center");
      if (alignCenter) {
        alignCenter.style.textAlign = "center";
      }
      const sizeSmall = containerRef.current.querySelector(".ql-size-small");
      if (sizeSmall) {
        sizeSmall.style.fontSize = "1rem";
      }
      const sizeLarge = containerRef.current.querySelector(".ql-size-large");
      if (sizeLarge) {
        sizeLarge.style.fontSize = "2rem";
      }
    }
  }, []);

  const contentHtml = data.content.replace(/<p style="margin: 0;"><\/p>/g, '<br />');

  return (
    <section className={Style.ownedMediaDetail}>
       <div style={{ display: "flex" }}>
        {data.category.map(category => (
          <span
            className={Style.mediaDetailContent}
            style={{ backgroundColor: category.color, color: "white", marginRight: "10px", padding: "5px 15px",borderRadius:"5px" }}
            key={category.name}
          >
            {category.name}
          </span>
        ))}
      </div>
      <h1>
        <span>{data.title}</span>
      </h1>
      <span style={{ color: "#5e5e5e" }}>
        {data.publishDate}
      </span>
      <span className={Style.catch}></span>
      <div style={{ textAlign:"center" }}>
      <img src={data.thumbnailImage} alt={data.title} style={{ maxWidth:"100%",maxHeight:"80%"}} />
      </div>
      <div className={Style.mediaDetailContent} ref={containerRef} dangerouslySetInnerHTML={{ __html: contentHtml }} />
    </section>
  );
};
export default MediaDetail;
